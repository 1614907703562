import styles from './PixelTracker.module.scss';


interface PixelTrackerProps {
    id: number
}

export const PixelTracker = (props: PixelTrackerProps) => {
    const {id} = props;
    const tagPositionName = 'position_' + id;
    const tagTypeName = 'BannerScript' + id + 'Type';
    const tagSourceName = 'BannerScript' + id;
    const tagCount = '' + id;

    return (
            <div className={styles.pixel_container}>
                <div>
                    <div className={styles.input_item}>
                        <span>Typ (Pixel {tagCount})</span>
                        <div className={styles.select_wrapper}>
                            <select id={tagTypeName} name={tagTypeName}  className={styles.select__dark}>
                                <option value="script">Raw Javascript</option>
                                <option value="url">Javascript URL</option>
                                <option value="image">Image URL</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div>
                    <div className={styles.input_item}>
                        <span>Tag (Pixel {tagCount})</span>
                        <textarea  id={tagSourceName} name={tagSourceName}  className={styles.textarea__dark}/>
                    </div>
                </div>
            </div>
    );
}
