import styles from './Head.module.scss';
import {PixelTracker} from "../pixel-tracker/PixelTracker";
import React, {useRef, useEffect, useState} from "react";
import {Branding} from "../branding/Branding";

export default function Head() {

    const handleSubmit = async (event: any) => {
        event.preventDefault()

        const endpoint = '/Api/Upload/ProcessForm'
        const options = {
            method: 'POST',
            headers: {
                'Content-Type': 'multipart/form-data '
            },
        }

        const response = await fetch(endpoint, options)

        const result = await response
        console.log(`Response: ${result}`)

    }
    const [selectedClient,setSelectedClient] = useState(0);
    const [selectedVideo,setSelectedVideo] = useState(0);
    const [selectedlayout,setSelectedLayout] = useState('1200');
    const contentImageSource = '/images/website-' +  selectedlayout + '.svg';
    const [selectedAdType, setAdType] = useState('branding');
    function handleAdTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
        const newAdType = event.target.value;
        setAdType(newAdType);

        if (newAdType === 'plachta') {
            setSelectedVideo(0);
        }
    };
    function handleSelectVideo(event: React.FormEvent<EventTarget>) {
        // @ts-ignore
        setSelectedVideo(event.target.value);
    }
    function handleSelectLayout(event: React.FormEvent<EventTarget>) {
        // @ts-ignore
        setSelectedLayout(event.target.value);
    }
    return (
        <form method="post"  action="/Api/Upload/ProcessForm" encType="multipart/form-data">
            <div className={styles.bbHead}>
                <div className={styles.logo}>
                    <img src="/images/logo-branding-builder.png" alt="" width={239} height={46}/>
                </div>
                <div className={styles.bbHead__controls}>
                    <div className={styles.inputItem}>
                        <span>Názov reklamnej kampane</span>
                        <input type="text" required placeholder="Moja reklamná kampaň" id={'BannerName'}  name={'BannerName'}
                               className={styles.input__dark}/>
                    </div>
                    <div className={styles.inputItem}>
                        <span>Meno spoločnosti</span>
                        <input type="text" required id={'BannerCompany'}  name={'BannerCompany'} placeholder="Firma s.r.o." className={styles.input__dark}/>
                    </div>
                    <div className={styles.inputItem}>
                        <span>Názov DSP a Seat ID</span>
                        <input type="text"  id={'BannerDspId'}  name={'BannerDspId'}  placeholder="Lorem Ipsum #3458" className={styles.input__dark}/>
                    </div>
                    <div className={styles.inputItem}>
                        <span>E-mail</span>
                        <input type="email" required id={'BannerEmail'}  name={'BannerEmail'} placeholder="meno.priezvisko@firma.sk" className={styles.input__dark}/>
                    </div>
                    <div className={styles.inputItem}>
                        <span>Použiť video</span>
                        <div className={styles.select_wrapper}>
                            <select className={styles.select__dark} onChange={handleSelectVideo} disabled={selectedAdType === 'plachta'} value={selectedVideo}>
                                <option value={0}>Nie</option>
                                <option value={1}>Áno</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.inputItem}>
                        <span>Šírka</span>
                        <div className={styles.select_wrapper}>
                            <select className={styles.select__dark} id={'BannerWidth'} name={'BannerWidth'} onChange={handleSelectLayout}>
                                <option value={1200}>1200px - Zvyšok porfólia</option>
                                <option value={1030}>1030px - Pluska.sk</option>
                            </select>
                        </div>
                    </div>
                    {/*<div className={styles.inputItem}>*/}
                    {/*    <span>Počet tracking pixelov</span>*/}
                    {/*    <div className={styles.select_wrapper}>*/}
                    {/*        <select value={selectedClient} className={styles.select__dark} onChange={handleSelectChange}>*/}
                    {/*            <option value={0}>0</option>*/}
                    {/*            <option value={1}>1</option>*/}
                    {/*            <option value={2}>2</option>*/}
                    {/*            <option value={3}>3</option>*/}
                    {/*        </select>*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                    <div className={styles.inputItem}>
                        <span>&nbsp;</span>
                        <input type="submit" className={styles.button} value="Vytvoriť kreatívu"/>
                    </div>
                </div>
                <div className={styles.setWrapper}>
                    <div className={styles.inputItem}>
                    <span>Typ</span>
                        <div className={styles.select_wrapper}>
                        <select className={styles.select__dark}  id={'AdType'}  name={'AdType'} onChange={handleAdTypeChange}>
                            <option value="branding">Branding</option>
                            <option value="plachta">Plachta</option>
                        </select>
                        </div>
                </div>
                    <div className={styles.inputItem}>
                        <span>Landing page URL</span>
                        <input type="text" required id={'ClickTag'}  name={'ClickTag'} placeholder="https://www.adcreative.com/id/234556"
                               className={styles.input__dark_url}/>
                    </div>
                </div>
                <div className={styles.pixelWrapper}>
                    {
                        [...Array(parseInt(selectedClient.toString()))].map((item, index) => <PixelTracker key={index} id={index + 1}/>)
                    }
                </div>
            </div>
            <Branding trackingPixelCount={selectedClient} activeAdType={selectedAdType} isVideoSource={selectedVideo} activeLayout={selectedlayout}/>
            <div className={styles.site_content}>
                 <img src={contentImageSource}/>
            </div>
        </form>

    );
}
