import styles from './SiteContent.module.scss';

const SiteContent = () => {

    return (

        <div>
        </div>
    )
        ;
};

export default SiteContent;
