import styles from './VideoBrowser.module.scss';
import React from 'react'
import {useRef, useEffect, useState} from "react";

interface VideoBrowserProps {
    id: string,
    videoSize: string
}

export const VideoBrowser = (props: VideoBrowserProps) => {
    const {id, videoSize} = props;

    const InputRef = useRef(null)


    const [src, setSrc] = useState("");

    const handleChange = (e: any) => {
        setSrc(URL.createObjectURL(e.target.files[0]));
        return true;
    };

    const handleClick = (event: any) => {
        // @ts-ignore
        InputRef.current.click();
    };
    return (
        <>
            <div className={styles.upload_video_wrapper}>
                {src?.length > 0 && (
                    <div className={styles.source_wrapper}>
                        <video
                            src={src}
                        />
                    </div>
                )}
                <div className={styles.upload_video}>
                    <input type="file" id={id} name={id} className={styles.hidden} ref={InputRef}
                           onChange={handleChange}/>
                    <img src="/images/icon-plus-white.svg" alt="" width={50} height={50}/>
                    <span><strong>UPLOAD VIDEO</strong><br/>(Pomer strán 16:9)<br/>MP4 - max. {videoSize}MB</span>
                </div>
                <div onClick={handleClick} className={styles.click_area}></div>
            </div>
        </>
    );
}
