import React from 'react';
import ReactDOM from 'react-dom/client';
import Head from './packages/head/Head';
import SiteContent from "./packages/site-content/SiteContent";

import './global.scss';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <Head/>
        <SiteContent/>
    </React.StrictMode>
);
