import styles from './FileBrowser.module.scss';
import React from 'react'
import {useRef, useEffect, useState} from "react";

interface FileBrowserProps {
    id: string,
    imageSize: string,
    imageWidth: string,
    imageHeight: string
}

export const FileBrowser = (props: FileBrowserProps) => {
    const {id, imageWidth, imageHeight, imageSize} = props;

    const InputRef = useRef(null)


    const [src, setSrc] = useState("");

    const handleChange = (e:any) => {
        setSrc(URL.createObjectURL(e.target.files[0]));
        return true;
    };


    const handleClick = (event: any) => {
        // @ts-ignore
        InputRef.current.click();
    };
    return (
        <>
            <div className={styles.upload_image_wrapper}>
                {src?.length > 0 && (
                    <div>
                        <img
                            src={src}
                            alt=""
                            width={imageWidth}
                            height={imageHeight}
                        />
                    </div>
                )}
                <div className={styles.upload_image}>
                    <input type="file" required id={id} name={id} className={styles.hidden} ref={InputRef}
                           onChange={handleChange}/>
                    <img src="/images/icon-plus-blue.svg" alt="" width={50} height={50}/>
                    <span>UPLOAD IMAGE<br/>JPG - max. {imageSize}kB<br/>{imageWidth}x{imageHeight}px</span>
                </div>
                <div onClick={handleClick} className={styles.click_area}></div>
            </div>
        </>
    );
}
