import styles from './Branding.module.scss';
import {FileBrowser} from "../file-browser/FileBrowser";
import {VideoBrowser} from "../video-browser/VideoBrowser";
import {PlachtaBrowser} from "../plachta-browser/PlachtaBrowser";

interface BrandingProps {
    trackingPixelCount: number,
    activeLayout: string,
    isVideoSource: number,
    activeAdType: string

}

export const Branding = (props: BrandingProps) => {
    const {trackingPixelCount, activeLayout, isVideoSource, activeAdType} = props;
    const layoutTopClassName = 'branding_top_w' + activeLayout;
    const layoutWrapperClassName = 'ad_wrapper_w' + activeLayout;
    const layoutLeftClassName = 'branding_left_w' + activeLayout;
    const layoutRightClassName = 'branding_right_w' + activeLayout;
    return (
        <>
            {activeAdType === 'plachta' ? (
                <div className={styles[`${layoutWrapperClassName}`]}>
                    <div className={styles.plachta_top}></div>
                    <div
                        className={styles.branding_l_r}>
                        <div className={styles.plachta_sticky}>
                            <PlachtaBrowser id={'Plachta'} imageWidth={'2000'} imageHeight={'1400'} imageSize={'2500'}/>
                        </div>
                    </div>
                </div>
            ) : (
                <div className={styles[`${layoutWrapperClassName}`]}>
                    <div
                        className={trackingPixelCount > 0 ? `${styles.branding_l_r} ${styles.pixel}` : styles.branding_l_r}>
                        <div className={styles[`${layoutLeftClassName}`]}>
                            <FileBrowser id={'BannerLeft'} imageWidth={'160'} imageHeight={'600'} imageSize={'400'}/>
                        </div>
                        <div className={styles[`${layoutRightClassName}`]}>
                            <FileBrowser id={'BannerRight'} imageWidth={'160'} imageHeight={'600'} imageSize={'400'}/>
                        </div>
                    </div>
                    <div className={styles[`${layoutTopClassName}`]}>
                        {isVideoSource > 0 &&
                            <div className={styles.branding_video}>
                                <VideoBrowser id={'BannerVideo'} videoSize={'50'}/>
                            </div>
                        }
                        <FileBrowser id={'BannerTop'} imageWidth={activeLayout} imageHeight={'200'} imageSize={'400'}/>

                    </div>
                </div>

            )}
        </>
    );
}
